define("discourse/plugins/waze-require-edit-reason/discourse/components/edit-enforce", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShow}}
    <div
      class="edit-enforcer
        {{unless this.hasEditReason '--danger'}}
        {{if this.getAttention '--animate'}}
        {{if this.highlight '--highlight'}}"
      {{didInsert this.setupListeners}}
      {{willDestroy this.cleanupListeners}}
      {{didUpdate this.resetState this.enforceEditReason.composerModel.action}}
    >
      {{#if this.hasEditReason}}
        {{d-icon "check"}}
      {{else}}
        {{d-icon "triangle-exclamation"}}
        {{i18n "form_kit.errors.required"}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "6c6Essjv",
    "block": "[[[41,[30,0,[\"shouldShow\"]],[[[1,\"  \"],[11,0],[16,0,[29,[\"edit-enforcer\\n      \",[52,[51,[30,0,[\"hasEditReason\"]]],\"--danger\"],\"\\n      \",[52,[30,0,[\"getAttention\"]],\"--animate\"],\"\\n      \",[52,[30,0,[\"highlight\"]],\"--highlight\"]]]],[4,[38,3],[[30,0,[\"setupListeners\"]]],null],[4,[38,4],[[30,0,[\"cleanupListeners\"]]],null],[4,[38,5],[[30,0,[\"resetState\"]],[30,0,[\"enforceEditReason\",\"composerModel\",\"action\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"hasEditReason\"]],[[[1,\"      \"],[1,[28,[35,6],[\"check\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,6],[\"triangle-exclamation\"],null]],[1,\"\\n      \"],[1,[28,[35,7],[\"form_kit.errors.required\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"unless\",\"didInsert\",\"willDestroy\",\"didUpdate\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/waze-require-edit-reason/discourse/components/edit-enforce.hbs",
    "isStrictMode": false
  });
  class EditEnforce extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "enforceEditReason", [_service.service]))();
    #enforceEditReason = (() => (dt7948.i(this, "enforceEditReason"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "hasEditReason", [_tracking.tracked], function () {
      return false;
    }))();
    #hasEditReason = (() => (dt7948.i(this, "hasEditReason"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "getAttention", [_tracking.tracked], function () {
      return false;
    }))();
    #getAttention = (() => (dt7948.i(this, "getAttention"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "highlight", [_tracking.tracked], function () {
      return false;
    }))();
    #highlight = (() => (dt7948.i(this, "highlight"), void 0))();
    inputHandler = () => {
      if (this.inputElement?.value.trim()) {
        this.editReasonElement?.classList.remove("--danger");
        this.composer.set("disableSubmit", false);
        this.hasEditReason = true;
        this.getAttention = false;
        this.highlight = false;
      } else {
        this.hasEditReason = false;
        this.highlight = true;
        this.composer.set("disableSubmit", true);
      }
    };
    attentionHandler = () => {
      this.getAttention = true;
      this.highlight = true;
      setTimeout(() => {
        this.getAttention = false;
      }, 500);
    };
    get editReasonElement() {
      return document.querySelector(".display-edit-reason");
    }
    get inputElement() {
      return document.getElementById("edit-reason");
    }
    get createButtonElement() {
      return document.querySelector(".create.disabled");
    }
    get shouldShow() {
      return this.enforceEditReason.enforceReason;
    }
    setupListeners() {
      this.inputElement?.addEventListener("input", this.inputHandler);
      this.createButtonElement?.addEventListener("pointerdown", this.attentionHandler);
    }
    static #_6 = (() => dt7948.n(this.prototype, "setupListeners", [_object.action]))();
    cleanupListeners() {
      this.inputElement?.removeEventListener("input", this.inputHandler);
      this.createButtonElement?.removeEventListener("pointerdown", this.attentionHandler);
    }
    static #_7 = (() => dt7948.n(this.prototype, "cleanupListeners", [_object.action]))();
    resetState() {
      this.enforceEditReason.toggleState(false);
    }
    static #_8 = (() => dt7948.n(this.prototype, "resetState", [_object.action]))();
  }
  _exports.default = EditEnforce;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditEnforce);
});
define("discourse/plugins/waze-require-edit-reason/discourse/connectors/category-custom-settings/waze-require-edit-reason-settings", ["exports", "@ember/object", "@glimmer/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WazeRequireEditReasonSettings extends _component.default {
    get category() {
      return this.args.outletArgs.category;
    }
    static #_ = (() => dt7948.n(this.prototype, "category", [(0, _object.computed)("args.outletArgs.category")]))();
  }
  _exports.default = WazeRequireEditReasonSettings;
});
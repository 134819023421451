define("discourse/plugins/waze-require-edit-reason/discourse/services/enforce-edit-reason", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EnforceEditReason extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "enforceReason", [_tracking.tracked], function () {
      return false;
    }))();
    #enforceReason = (() => (dt7948.i(this, "enforceReason"), void 0))();
    toggleState(state) {
      this.composer.setProperties({
        disableSubmit: state,
        showEditReason: state
      });
      this.enforceReason = state;
    }
    get composerModel() {
      return this.composer.model;
    }
    get isEditMode() {
      return this.composerModel?.action === "edit";
    }
    get isCategoryRequiringEditReason() {
      return !!this.composerModel?.topic?.category?.custom_fields?.waze_require_edit_reason;
    }
    get isPostWiki() {
      return !!this.composerModel?.post?.wiki;
    }
    get shouldEnforce() {
      return this.isEditMode && this.isCategoryRequiringEditReason && this.isPostWiki;
    }
  }
  _exports.default = EnforceEditReason;
});
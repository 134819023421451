define("discourse/plugins/waze-require-edit-reason/discourse/templates/connectors/composer-action-after/edit-enforce", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <EditEnforce />
  */
  {
    "id": "udjbD6JY",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"edit-enforce\"]]",
    "moduleName": "discourse/plugins/waze-require-edit-reason/discourse/templates/connectors/composer-action-after/edit-enforce.hbs",
    "isStrictMode": false
  });
});
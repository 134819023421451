define("discourse/plugins/waze-require-edit-reason/discourse/templates/connectors/category-custom-settings/waze-require-edit-reason-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>Waze Require Edit Reason</h3>
  <section class="field">
    <div class="controls">
      <label class="checkbox-label">
        <Input
          @type="checkbox"
          @checked={{this.category.custom_fields.waze_require_edit_reason}}
        />
        Require users to provide edit reason when editing a post?
      </label>
    </div>
  </section>
  */
  {
    "id": "I0cxLOJZ",
    "block": "[[[10,\"h3\"],[12],[1,\"Waze Require Edit Reason\"],[13],[1,\"\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"waze_require_edit_reason\"]]]],null],[1,\"\\n      Require users to provide edit reason when editing a post?\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"h3\",\"section\",\"div\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/waze-require-edit-reason/discourse/templates/connectors/category-custom-settings/waze-require-edit-reason-settings.hbs",
    "isStrictMode": false
  });
});